import React, { useState } from 'react';
import Link from 'next/link';
import Headroom from 'react-headroom';
import { useRouter } from 'next/router';

import { useRemoteConfig } from '@app/context/remote-config';

import { HeaderStyled, HeaderTopStyled, LogoStyled, MainNavStyled } from './header-styled';

const Header = (): JSX.Element => {
  const [navActive, setNavActive] = useState(false);
  const router = useRouter();
  const { isReady, settings } = useRemoteConfig();

  const isActive = (path: string) => {
    let isActiveClass = '';
    if (router.pathname === path) {
      isActiveClass = 'active';
    }
    return isActiveClass;
  };

  return (
    <Headroom className="z-20 fixed w-full">
      <HeaderStyled>
        <HeaderTopStyled>
          <LogoStyled>
            <Link href="/">
              <a>
                <img
                  src="/assets/images/KCC_Multiplex.svg"
                  width={220}
                  height={23}
                  alt="KCC Multiplex"
                />
              </a>
            </Link>
          </LogoStyled>

          <MainNavStyled>
            <div className={`navWrapper ${navActive ? 'show' : ''}`}>
              <Link href="/">
                <a className={isActive('/')}>Home</a>
              </Link>
              {settings?.showGiveaways ? (
                <a
                  className="giveaways-btn"
                  href="https://giveaways.kccmultiplex.lk"
                  target="_blank"
                  rel="noreferrer">
                  Giveaways
                </a>
              ) : null}
              {isReady && settings?.temporaryDisable === false ? (
                <Link href="/buy-tickets">
                  <a className={`bookin-btn ${isActive('/buy-tickets')}`}>Buy Tickets</a>
                </Link>
              ) : null}
              <Link href="/movies">
                <a className={isActive('/movies')}>Movies</a>
              </Link>
              <Link href="/cinemas">
                <a className={isActive('/cinemas')}>Cinemas</a>
              </Link>
              <Link href="/contact-us">
                <a className={isActive('/contact-us')}>Contact Us</a>
              </Link>
            </div>

            <button
              type="button"
              className={`mobile-nav-button text-white ${navActive ? 'active' : ''}`}
              onClick={() => setNavActive(!navActive)}>
              <svg width="100" height="100" viewBox="0 0 100 100">
                <path
                  className="line line1"
                  d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                />
                <path className="line line2" d="M 20,50 H 80" />
                <path
                  className="line line3"
                  d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                />
              </svg>
            </button>
          </MainNavStyled>
        </HeaderTopStyled>
      </HeaderStyled>
    </Headroom>
  );
};

export default Header;
