module.exports = {
  purge: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './layouts/**/*.{js,ts,jsx,tsx}',
  ],
  darkMode: false, // or 'media' or 'class'
  theme: {
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
    },
    extend: {
      fontFamily: {
        sans: ['Mulish', 'Helvetica', 'Arial', 'sans-serif'],
      },
      extend: {
        maxWidth: {
          '1/4': '25%',
          '1/2': '50%',
          '3/4': '75%',
          'xl-kcc': '1360px',
         }
      },
      colors: {
        primary: {
          100: '#f5f5f5',
          200: '#EFF3FF',
          300: '#C6CFEB',
          400: '#4261C1',
          500: '#FF9800',
          600: '#757575',
          700: '#616161',
          800: '#424242',
          900: '#212121',
        },
        gray: {
          100: '#D8D8D8',
          200: '#9E9E9E',
          300: '#888888',
          400: '#575757',
          500: '#494949',
          600: '#2e2e30',
          700: '#2b2b2b',
          800: '#2a2620',
          900: '#1a1a1a',
        },
        blue: {
          100: '#607D8B',
          500: 'B383838',
          600: '#333333',
        }
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [
    require('@tailwindcss/custom-forms'),
  ],
};
