import styled from 'styled-components';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const NewsletterStyled = styled.div`
  h3 {
    margin-bottom: 0.5rem !important;
  }
  form {
    margin: 0 -44px;

    @media (min-width: ${fullConfig.theme.screens.md}) {
      margin: 0 auto;
    }
    input {
      padding: 10px 16px 14px;
      width: auto !important;
      height: 42px;

      &:disabled {
        opacity: 0.5;
      }
    }

    button {
      margin-top: 0;

      &:disabled {
        opacity: 0.5;
      }
    }
  }
`;
