/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { NewsletterStyled } from './newsletter-styled';

type SubscribeForm = {
  email: string;
};

export default function Newsletter(): JSX.Element {
  const [formLoading, setFormLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SubscribeForm>();
  const onSubmit = async ({ email }: SubscribeForm) => {
    try {
      // submit
      setFormLoading(true);
      const response = await fetch('/api/newsletter/subscribe', {
        method: 'POST',
        body: JSON.stringify({ email }),
      });
      const results = await response.json();
      console.log(results);
      reset();
      if (results.success) {
        toast(results.data.message, {
          type: 'success',
          autoClose: 3000,
        });
      } else {
        toast(results.error.message, {
          type: 'error',
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log(error);
      toast(error, {
        type: 'error',
        autoClose: 3000,
      });
      console.error(error);
    } finally {
      setFormLoading(false);
    }
  };
  return (
    <NewsletterStyled>
      <h3 className="text-3xl">Newsletter</h3>
      <p className="mb-7">Join our mailing list for promotions and latest movie updates.</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-center lg:justify-start">
          <input
            disabled={formLoading}
            className="block rounded bg-gray-100 text-black outline-none mr-2"
            type="email"
            style={{ width: 212 }}
            {...register('email', {
              required: true,
              pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            placeholder="Type your email address"
          />

          <button className="btn--blue" type="submit" aria-label="Subscribe" disabled={formLoading}>
            Subscribe
          </button>
        </div>
        <div className="flex justify-center text-red-500 lg:justify-start">
          {errors.email && <span>Please enter a valid email.</span>}
        </div>
      </form>
    </NewsletterStyled>
  );
}
