import styled from 'styled-components';
import tw from 'twin.macro';

export const FooterStyled = styled.footer`
  ${tw`flex  bg-gray-800 flex-col`}
`;

export const FooterTopStyled = styled.div`
  ${tw`flex p-3 bg-gray-900 w-full`}

  .footer-container {
    ${tw`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 bg-gray-900 px-8 py-12 justify-between text-white w-full mx-auto`}
    max-width: 1360px;

    .footer-col {
      ${tw`flex flex-col`}

      h3 {
        ${tw`text-3xl font-bold mb-5 text-center lg:text-left`}
      }

      a {
        ${tw`transition transition-all ease-in-out duration-200`}
        ${tw`flex text-center lg:text-left flex-col mb-4 hover:text-primary-500`}

        &:last-child {
          ${tw`mb-16 lg:mb-10`}
        }
      }

      p {
        ${tw`text-center lg:text-left`}
      }
    }
  }

  .footer-social {
    ${tw`flex pb-3 items-center mb-10 lg:mb-6 justify-center lg:justify-start`}

    a {
      ${tw`transition transition-all ease-in-out duration-200`}
      ${tw`flex ml-5 mr-5 lg:ml-0 lg:mr-10 items-center justify-center w-8 h-8 hover:text-white hover:fill-current`}
      margin-bottom: 0!important;
    }
  }

  .new-latter {
    ${tw`mt-4`}

    button {
      ${tw`text-base font-normal transition transition-all ease-in-out duration-200 rounded-md text-center px-4 pt-2 pb-3 cursor-pointer`}
      ${tw`bg-primary-500 border border-primary-500 text-black hover:text-primary-500 hover:bg-transparent ml-2`}
    }

    input {
      ${tw`text-base font-normal transition transition-all ease-in-out duration-200 rounded-md text-left px-4 pt-2 pb-3 cursor-pointer`}
      ${tw`bg-gray-100 border border-gray-100 text-black`}
    }
  }
`;

export const FooterBottomStyled = styled.div`
  ${tw`flex p-3 bg-gray-800 w-full`}

  .footer-container {
    ${tw`flex flex-col lg:flex-row px-8 py-2 justify-between text-white w-full mx-auto`}
    max-width: 1360px;

    div {
      ${tw`text-center lg:text-left`}
    }

    a {
      ${tw`transition transition-all ease-in-out duration-200`}
      ${tw`hover:text-primary-500 mr-4`}
    }

    .copyright-text {
      ${tw`text-blue-500 mt-6 lg:mt-0`}
    }
  }
`;
