import { createContext, useContext, useEffect, useState } from 'react';
// import dynamic from 'next/dynamic';

import { firebase } from '@app/config/fire-conf';
import { Settings } from '@app/types';

type RemoteConfigProviderValueType = {
  isReady: boolean;
  settings: Partial<Settings> | null;
};

type RemoteConfigProviderPropType = {
  children: React.ReactNode;
};

const RemoteConfigContext = createContext<RemoteConfigProviderValueType>({
  isReady: false,
} as RemoteConfigProviderValueType);

export const RemoteConfigProvider = ({ children }: RemoteConfigProviderPropType): JSX.Element => {
  const [ready, setReady] = useState(false);
  const [mounted, setMounted] = useState(true);
  const [settings, setSettings] = useState<Partial<Settings> | null>(null);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    firebase
      .firestore()
      .collection('settings')
      .get()
      .then((result) => {
        result.forEach((document) => {
          const data = document.data();
          setSettings((currentSettings) => ({ ...currentSettings, ...data }));
        });
        setReady(true);
      })
      .catch((error) => console.error(error));

    return () => {
      setMounted(false);
    };
  }, [mounted]);
  return (
    <RemoteConfigContext.Provider value={{ settings, isReady: ready }}>
      {children}
    </RemoteConfigContext.Provider>
  );
};

export const useRemoteConfig = (): RemoteConfigProviderValueType => {
  return useContext(RemoteConfigContext);
};
