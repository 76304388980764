import React from 'react';
import Link from 'next/link';

import Image from '@app/components/image';
import Messenger from '@app/components/messenger';
import GoogleAnalytics from '@app/components/google-analytics';

import Newsletter from '../newsletter';

import { FooterStyled, FooterTopStyled, FooterBottomStyled } from './footer-styled';

const today = new Date();

const Footer = (): JSX.Element => {
  return (
    <FooterStyled id="footer">
      <FooterTopStyled>
        <div className="footer-container">
          <div className="footer-col">
            <h3>KCC Multiplex</h3>
            <Link href="/buy-tickets">Buy tickets</Link>
            <Link href="/movies">Movies</Link>
            <Link href="/cinemas">Cinemas</Link>
            <Link href="/contact-us">Contact us</Link>
          </div>
          <div className="footer-col">
            <h3>General</h3>
            <a href="https://kandycitycentre.lk" target="_blank" rel="noreferrer">
              Kandy City Centre
            </a>
            {/* <Link href="/about-us">About us</Link>
            <Link href="/faq">FAQ’s</Link> */}
            <Link href="/covid-19-guidelines/en/">COVID-19 guidelines</Link>
          </div>
          <div className="footer-col md:col-span-2 lg:col-span-1">
            <div className="footer-social">
              <a href={process.env.NEXT_PUBLIC_SOCIAL_FACEBOOK} target="blank" rel="noreferrer">
                <Image width="32" height="32" src="/assets/icons/facebook.svg" alt="facebook" />
              </a>
              <a href={process.env.NEXT_PUBLIC_SOCIAL_INSTAGRAM} target="_blank" rel="noreferrer">
                <Image width="32" height="32" src="/assets/icons/instagram.svg" alt="instagram" />
              </a>
              {/* <a href={process.env.NEXT_PUBLIC_SOCIAL_YOUTUBE} target="_blank" rel="noreferrer">
                <Image width="32" height="32" src="/assets/icons/youtube.svg" alt="youtube" />
              </a> */}
            </div>
            <Newsletter />
          </div>
        </div>
      </FooterTopStyled>
      <FooterBottomStyled>
        <div className="footer-container">
          <div>
            <Link href="/privacy-policy">Privacy policy</Link>
            <Link href="/terms-and-conditions">Terms and conditions</Link>
          </div>
          <div className="copyright-text">
            &copy; {today.getFullYear()} Property Finance and Investments Kandy (Pvt) Ltd. All
            rights reserved.
          </div>
        </div>
      </FooterBottomStyled>
      <GoogleAnalytics />
      <Messenger />
    </FooterStyled>
  );
};

export default Footer;
