import Script from 'next/script';

export default function Messenger(): JSX.Element {
  return (
    <>
      <div id="fb-root" />
      <div id="fb-customerchat" className="fb-customerchat" />
      <Script id="fb-messenger-chat" key="fb-messenger-chat" strategy="lazyOnload">
        {`
            var chatbox = document.getElementById('fb-customerchat');
            if (chatbox) {
              chatbox.setAttribute("page_id", "103701872100973");
              chatbox.setAttribute("attribution", "install_email");
              chatbox.setAttribute("attribution_version", "biz_inbox");
      
              window.fbAsyncInit = function() {
                FB.init({
                  xfbml            : true,
                  version          : 'v12.0'
                });
              };
              
              (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
                fjs.parentNode.insertBefore(js, fjs);
              }(document, 'script', 'facebook-jssdk'));
            }
            
        `}
      </Script>
    </>
  );
}
