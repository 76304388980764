import styled from 'styled-components';
import tw from 'twin.macro';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

export const HeaderStyled = styled.header`
  ${tw`lg:p-5 flex justify-center`};
`;

export const HeaderTopStyled = styled.div`
  ${tw`flex justify-between p-1 pl-3 lg:px-8 pr-1 items-center bg-black bg-opacity-75 lg:rounded-sm h-14 lg:h-20 max-w-xl w-full`}
  max-width: 1360px;
`;

export const LogoStyled = styled.div`
  ${tw`flex z-10`}
  width: clamp(200px, 30%, 220px);
  min-width: 200px;
`;

export const MainNavStyled = styled.div`
  ${tw`col-auto flex items-center`}

  .mobile-nav-button {
    ${tw`lg:hidden z-40 scale-50`}
    transform: scale(0.5) translateX(25%);
    outline: none;

    .line {
      fill: none;
      stroke: white;
      stroke-width: 5;
      transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .line1 {
      stroke-dasharray: 60 207;
      stroke-width: 5;
    }

    .line2 {
      stroke-dasharray: 60 60;
      stroke-width: 5;
    }

    .line3 {
      stroke-dasharray: 60 207;
      stroke-width: 5;
    }

    &.active .line1 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 5;
    }
    &.active .line2 {
      stroke-dasharray: 1 60;
      stroke-dashoffset: -30;
      stroke-width: 5;
    }
    &.active .line3 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 5;
    }
  }

  .navWrapper {
    ${tw`fixed flex p-8 lg:p-0 lg:relative transition transition-all ease-in-out duration-300 flex-col lg:flex-row justify-center items-center`}
    ${tw`w-full h-screen lg:h-full left-0 lg:relative bg-black lg:bg-transparent bg-opacity-100 top-0`}
    transform: translateY(-100%);

    @media (min-width: ${fullConfig.theme.screens.lg}) {
      transform: translateY(0);
    }

    &.show {
      ${tw``}
      transform: translateY(0);
    }
  }

  a {
    ${tw`text-white px-4 pt-2 pb-2 mx-2 text-center`}
    ${tw`transition transition-all ease-in-out duration-200 rounded-md hover:text-primary-500 border-blue-600`};
    ${tw`w-full lg:w-auto border lg:border-none mb-3 lg:mb-0`};

    @media (min-width: ${fullConfig.theme.screens.lg}) {
    }

    &.active {
      ${tw`text-primary-500 border-primary-500`}
    }

    &.bookin-btn {
      ${tw`bg-primary-500 border border-primary-500 text-black hover:bg-primary-500 hover:text-black`}
    }

    &.giveaways-btn {
      ${tw`border border-solid text-primary-500 border-primary-500 hover:bg-primary-500 hover:text-black`}
    }
  }
`;
