import React, { ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from '@app/components/header';
import Footer from '@app/components/footer';

type Props = {
  children?: ReactNode;
};

const Layout = ({ children }: Props): JSX.Element => (
  <>
    <Header />
    <ToastContainer />
    <main>{children}</main>
    <Footer />
  </>
);

export default Layout;
